import animatedImage from './animatedImage.module.scss'
import articlesGrid from './articlesGrid.module.scss'
import cities from './cities.module.scss'
import companyMerger from './companyMerger.module.scss'
import featuredArticles from './featuredArticles.module.scss'
import hero from './hero.module.scss'
import highlights from './highlights.module.scss'
import infographic from './infographic.module.scss'
import leadership from './leadership.module.scss'
import recentArticles from './recentArticles.module.scss'
import richText from './richText.module.scss'
import staticImage from './staticImage.module.scss'

export default { animatedImage, articlesGrid, cities, companyMerger, featuredArticles, hero, highlights, infographic, leadership, recentArticles, richText, staticImage }
